// eslint-disable-next-line no-unused-vars
import { AppState, useStore } from "../store/store";
import { LogStatus, logger } from "../utils/DDLoggerHelper";
import { logOut, setAccessTokenFetcher, setIdTokenFetcher } from "../constant/api";
import { useEffect, useState } from "react";
import { AgentClient } from "../services/AgentClient";
// eslint-disable-next-line no-unused-vars
import { MeritUserInfo } from "../types/user";
import { None } from "../utils/None";
import { Some } from "../utils/Some";
// eslint-disable-next-line no-unused-vars
import type { UseMeritAuth0 } from "../types/auth";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import jwt_decode from "jwt-decode";
import { minimumLoginAuthScopes } from "../constant/common";
import { showErrorToast } from "../utils/ToastHelper";
import { useAuth0 } from "@auth0/auth0-react";

const setIntermediateScreenSelector = (state: AppState) => state.setIntermediateScreen;
const clearAppStateSelector = (state: AppState) => state.clearAppState;

const useLogin = () => {
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, loginWithPopup, logout, loginWithRedirect } =
    useAuth0();
  const setIntermediateScreen = useStore(setIntermediateScreenSelector);
  const setAdminEntityId = useStore((state: AppState) => state.setAdminEntityId);
  const adminEntityId = useStore((state: AppState) => state.adminEntityId);

  setAccessTokenFetcher(getAccessTokenSilently);
  setIdTokenFetcher(getIdTokenClaims);

  const logoutAndReturn = () => {
    const isWebCheckin = window.location.pathname.includes("web-checkin");
    logout({
      logoutParams: {
        returnTo: isWebCheckin ? `${window.location.origin}/web-checkin/home` : window.location.origin,
      },
    });
  };

  const tryGetLinks = async (agentID: string, token: string) => {
    try {
      const agentClient = AgentClient(token);
      const response = await agentClient.getLinks(agentID);

      return { ...response, success: true };
    } catch {
      showErrorToast({
        message:
          "You must finish setting up your account and accepting all terms and conditions before using Merit tooling",
        onClose: logoutAndReturn,
      });

      return { capabilitiesApproved: false, success: false, tosAccepted: false };
    }
  };

  const doPkceFlow = async (auth0OrgId?: string) => {
    try {
      if (None(auth0OrgId)) {
        await loginWithPopup({
          authorizationParams: {
            audience: process.env.AUTH0_AUDIENCE_URL,
            prompt: "login",
            scope: minimumLoginAuthScopes,
          },
        });

        const token = await getAccessTokenSilently();
        const { agentID, entityID } = jwt_decode<MeritUserInfo>(token);
        const isWebCheckinURL = window.location.pathname.includes("web-checkin");

        if (isWebCheckinURL) {
          datadogRum.setUser({ checkinUserId: entityID, id: Some(adminEntityId) ? adminEntityId : entityID });
          datadogLogs.setUser({ checkinUserId: entityID, id: Some(adminEntityId) ? adminEntityId : entityID });
        } else {
          setAdminEntityId(entityID);
          datadogRum.setUser({ id: entityID });
          datadogLogs.setUser({ id: entityID });
        }

        const response = await tryGetLinks(agentID, token);
        if (response.success) {
          const { capabilitiesApproved, tosAccepted } = response;

          if (!capabilitiesApproved || !tosAccepted) {
            setIntermediateScreen("termsOfService");
          } else if (capabilitiesApproved && tosAccepted) {
            setIntermediateScreen("selectOrg");
          }
        }
      } else {
        /**
         * This is the case when the user is logging in to a specific organization.
         * This uses a redirection flow for login, which is more user-friendly compared to opening a popup.
         */
        await loginWithRedirect({
          authorizationParams: {
            audience: process.env.AUTH0_AUDIENCE_URL,
            organization: auth0OrgId,
            prompt: "select_account",
            redirect_uri: `${window.location.origin}/org-login-success`,
            scope: minimumLoginAuthScopes,
          },
        });
      }
    } catch (err) {
      logger("Failed to login", LogStatus.ERROR, {}, err);
      // Passing onClose function to handle the case when auth0 login is successfull but any
      // other api call fails
      showErrorToast({
        message: "There was a problem logging in",
        onClose: () => {
          if (isAuthenticated) {
            logoutAndReturn();
          }
        },
      });
      throw err;
    }
  };

  return doPkceFlow;
};

const useLogout = () => {
  const { logout: logoutAuth0 } = useAuth0();

  const clearAppState = useStore(clearAppStateSelector);
  const setAdminEntityId = useStore((state: AppState) => state.setAdminEntityId);

  const logout = async (onOpenUrl?: (url: string) => void) => {
    await logOut();
    // We handle the redirection logic from code
    logoutAuth0({
      logoutParams: {
        returnTo: window.location.pathname.includes("web-checkin")
          ? `${window.location.origin}/web-checkin/home`
          : window.location.origin,
      },
      async openUrl(url) {
        // Clear the app state
        clearAppState();
        setAdminEntityId(undefined);
        datadogRum.clearUser();
        datadogLogs.clearUser();
        if (Some(onOpenUrl)) {
          onOpenUrl(url);
        } else {
          window.location.assign(url);
        }
      },
    });
  };

  return logout;
};

const useMeritAuth0 = (): UseMeritAuth0 => {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading, user } = useAuth0();

  setAccessTokenFetcher(getAccessTokenSilently);
  setIdTokenFetcher(getIdTokenClaims);

  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    let hasCleanedUp = false;
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      if (!hasCleanedUp) {
        setAccessToken(token);
      }
    };

    // Only try to get the accessToken if we are authenticated to avoid console errors
    if (isAuthenticated) {
      getAccessToken();
    }

    return () => {
      hasCleanedUp = true;
    };
  }, [getAccessTokenSilently, isAuthenticated]);

  return {
    accessToken: accessToken ?? "",
    isAuthenticated,
    isLoading,
    // Looks weird, but convert undefined to null for matching API
    user: user === undefined ? null : user,
  };
};

export { useLogin, useLogout, useMeritAuth0 };
